import React from 'react';

import type {DzrGetStaticProps, DzrPage} from '@app/types/next';
import {COBRANDED_APPS} from '@app/types/next';
import {getTemplate} from '@app/modules/server/template';
import type {GetStaticPaths} from 'next';
import {useAuthController} from '@app/hooks/controllers/useAuthController';
import {AuthLayout} from '@app/components/Layouts/pages/AuthLayout';
import {getStaticTranslations} from '@app/modules/translations';
import {AuthForm} from '@app/components/AuthForms';
import {useTranslation} from '@app/hooks/useTranslation';
import {config} from '@app/modules/config';
import {AccountSection} from '@app/components/Layouts/elements';

const SignUpPage: DzrPage = () => {
	const {authCallback, isLoading, authConfig} = useAuthController();
	const t = useTranslation();

	const isSmartJourney =
		authConfig.data?.journeys.smart?.template === 'smart_v2';

	return (
		<AuthLayout
			isLoading={isLoading}
			title={isLoading || isSmartJourney ? '' : t('formal.title_register')}
		>
			<AccountSection>
				<AuthForm
					authConfig={authConfig.data}
					intent="register"
					onSuccess={authCallback}
				/>
			</AccountSection>
		</AuthLayout>
	);
};

export const getStaticProps: DzrGetStaticProps = async ({
	locale = '',
	params,
}) => {
	const translations = await getStaticTranslations(locale, {
		appName: params?.appName,
		additionnalNamespaces: [
			'error',
			'password',
			'revokePassword',
			'signup',
			'offer',
		],
	});
	const {appName, colorMode, template} = getTemplate(params?.appName);
	return {
		props: {
			seo: {
				title: ['signup.seotitle'],
				description: [
					'signup.seodescription',
					{
						count: config.get('totalTracks'),
						sprintf: [config.get('totalTracks')],
					},
				],
			},
			appName,
			colorMode,
			template,
			...translations,
		},
	};
};

export const getStaticPaths: GetStaticPaths = ({locales}) => {
	const paths: {locale: string; params: {appName: string}}[] = [];
	locales?.forEach((locale) => {
		COBRANDED_APPS.forEach((cobrandedApp) => {
			paths.push({
				params: {appName: cobrandedApp.toLowerCase()},
				locale,
			});
		});
	});
	return {
		paths,
		fallback: false,
	};
};

export default SignUpPage;
